@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/typography';

.builder-main {
  //margin: 24px auto 0;
  width: 100%;
  &__header {
    margin-left: 50px;
    padding: 15px 0;
    &__save-button {
      position: absolute !important;
      right: 30px !important;
      top: 20px !important;
      width: 200px !important;
      display: inline-block !important;
    }
  }
  &__wrapper {
    display: flex;
  }
  &__sequence {
    padding: 25px;
    background-color: $white;
    border: 1px solid $secondary-off-white;
    width: 28%;
    // previously was height: calc(100vh - 4.1rem);
    height: 100vh;
    overflow: auto;
    border-radius: 5px;
    //margin: 0 10px 0 50px;
    > h5 {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-size: 0.9rem;
      img {
        height: auto;
        width: 16px;
        margin-right: 10px;
      }
    }
    &-meta {
      margin-bottom: 15px;
      margin-top: 50px;
      h5 {
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: 0.8rem;
      }
      p {
        font-size: 0.75rem;
      }
    }
  }

  &__block-area {
    // margin-top: 20px;
    position: relative;
    padding: 20px 40px 40px;
    flex: 1;
    height: 100vh;
    overflow: auto;
    max-width: 60% !important;
    //margin-right: 240px;
  }

  &__dock {
    background-color: $white;
    border: 1px solid $secondary-off-white;
    padding: 30px 1px 30px 15px;
    position: fixed;
    // previously was 4rem
    top: 0;
    right: 0px;
    height: 100vh;
    width: 130px;

    h4 {
      text-transform: uppercase;
      text-align: center;
      color: $secondary-3;
      font-size: 1rem;
    }

    &-container {
      display: flex;
      flex-wrap: wrap;
      max-height: 600px;
      max-width: 100px;
      overflow: hidden;
      transition: max-height 0.4s ease-in;

      &.trigger-more {
        max-height: 600px;
        overflow: auto;
      }
    }
  }
  &__alert {
    //max-width: 670px;
  }
}

.sequence-card {
  background-color: $secondary-demi-white;
  padding: 5px 10px 10px;
  cursor: pointer;
  position: relative;
  margin-bottom: 15px;
  color: darken($secondary-4, 20%);
  &__search {
    margin-bottom: 20px;
    position: sticky;
    top: -25px;
    z-index: 1;
    padding-top: 15px;
    background: $white;
    color: darken($secondary-4, 20%);
  }
  &__title {
    font-size: 0.8rem !important;
    font-weight: 500;
    color: $secondary-3;
    display: inline-block;
    vertical-align: middle;
    svg {
      display: inline-block;
      vertical-align: middle;
    }
  }
  p {
    font-size: 0.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .edit-icon {
    position: absolute;
    top: 3px;
    right: 5px;
    &__active {
      svg {
        //fill: $white !important;
      }
    }
  }
  &__active {
    background: $secondary-off-white;
    color: darken($secondary-4, 60%);
    > .sequence-card__title {
      color: $secondary-2;
    }
  }
  &__textInput-invisible {
    width: 93% !important;
    box-shadow: none !important;
    font-weight: 500 !important;
    font-size: 0.8rem !important;
    color: $secondary-3 !important;
    //padding: 0px;
    margin: -5px 0 !important;
    font-family: $poppins !important;
    background: $secondary-off-white !important;
    &:hover,
    &:focus,
    &:active {
      box-shadow: none !important;
    }
  }
}
.width-90percent {
  width: 90%;
}

.width-100percent {
  width: 100%;
}

.block-icon-card {
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1 1 80px;
  background-color: #fff;
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in;
  &:hover {
    background: $secondary-off-white;
  }
  img {
    width: 30px;
  }
  p {
    margin-top: 10px;
    font-size: 10px;
    text-align: center;
    transition: all 0.2s ease-in;
  }
}

.more-dialog-container {
  padding: 10px 20px;
  &__section {
    margin-top: 15px;
    &__title {
      color: $secondary-3;
      font-size: 1rem;
      font-weight: 400;
      font-family: $poppins;
      margin-bottom: 5px;
    }
    &__card-container {
      display: flex;
      flex-wrap: wrap;
      max-height: 600px;
      width: 100%;
      overflow: hidden;
      transition: max-height 0.4s ease-in;
      .block-icon-card {
        max-width: 85px;
      }
    }
  }
}

//scrollbar fixes

.builder-main__sequence::-webkit-scrollbar,
.builder-main__block-area::-webkit-scrollbar {
  width: 0.25rem !important;
}

.builder-main__sequence::-webkit-scrollbar-track,
.builder-main__block-area::-webkit-scrollbar-track {
  background: $tint !important;
}

.builder-main__sequence::-webkit-scrollbar-thumb,
.builder-main__block-area::-webkit-scrollbar-thumb {
  background: $tint2 !important;
}

.builder-main__block-area::-webkit-scrollbar {
  display: none;
}
.block-rules {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__save-section {
    margin-top: 20px;
  }
}
.story-block-question {
  &__container {
    padding: 20px;
    border: 1px solid $secondary-off-white;
    margin-bottom: 20px;
    border-radius: 5px;
  }
}
