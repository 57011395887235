@import "../colors";
.subscribe-container {
  &__sequence-card {
    background: $secondary-demi-white;
    margin: 10px auto;
    border: 1px solid $secondary-off-white;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      margin-right: 10px;
    }
  }
}
