.dropZone {
  flex: 0 0 auto;
  width: 580px;
  height: 12px;
  border-radius: 6px;
  margin: 2px 0;
  transition: 200ms all;
}

.dropZone:nth-of-type(2n) {
  display: none;
}

.dropZone.active {
  background-color: #dbe9fe;
  height: 60px;
}
