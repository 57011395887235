@import "../../../../assets/styles/typography";
@import "../../../../assets/styles/colors";

.audience-table {
  height: 100%;
  border: 1px solid rgba(235, 235, 235, 0.7);
  background: white;
}
.no-data-alert {
  text-align: center;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.audience-filter {
  display: flex;
  margin-top: 10px;
  position: relative;
  &__popover {
    padding: 0;
  }
  &__title {
    font-size: 1.4rem;
    font-family: $poppins;
    border-bottom: 1px solid $tint;
    text-align: center;
    padding: 8px 0;
    color: $secondary-3;
  }
  &__body {
    height: calc(100% - 120px);
    overflow: scroll;
    padding: 20px;
  }
  &__condition {
    display: flex;
    justify-content: space-around;
    padding-bottom: 10px;
    &-remove {
      width: 80px !important;
      height: 30px !important;
      box-shadow: none !important;
    }
  }
  &__no-data-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 120px);
    flex-direction: column;
  }
  &__footer {
    position: absolute !important;
    bottom: 0 !important;
    padding: 10px 10px 20px !important;
    display: flex !important;
    justify-content: space-around !important;
    align-items: center !important;
    width: 100% !important;
    border-top: 1px solid $tint !important;
  }
  &__dropdown {
    width: 98% !important;
    margin-right: 2% !important;
    display: flex !important;
    justify-content: space-between !important;
    background: $white !important;
    &:hover {
      background: $white !important;
    }
  }
}
.no-filter {
  &__message {
    font-size: 0.9rem;
    text-transform: uppercase;
    color: rgba($primary, 0.4);
    margin-bottom: 30px;
  }
}
.audience__datePicker {
  width: 100% !important;
  margin-right: 2% !important;
  input {
    width: 100%;
    height: 30px !important;
  }
}

.text-wrap__child {
  span {
    white-space: normal;
  }
}
