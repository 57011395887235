.permission-project-settings {
  height: fit-content;
  background: white;
  border: 1px solid $tint3;
  border-radius: 5px;
  padding: 30px 50px 15px;
  overflow: auto;
}
//header style
.permission-project-header {
  display: flex;
  &__info {
    flex: 4;
    display: flex;
  }
  &__title {
    color: $secondary-3;
  }
  &__subtitle {
    color: $secondary-4;
    font-size: 0.8rem;
    text-transform: uppercase;
  }
  &__searchInput {
    flex: 3;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    input {
      box-shadow: none;
      border: 1px solid $tint3;
      &:focus {
        box-shadow: none;
        border: 1px solid $tint2;
      }
    }
  }
}
.custom-textCell {
  span {
    font-family: $poppins !important;
  }
}
.avatar-cell {
  span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: $poppins;
  }
}

.role-button {
  box-shadow: none !important;
  background: $tint !important;
  &:focus {
    box-shadow: none !important;
  }
}
.no-data__container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    padding: 5px 10px;
    border-radius: 5px;
    text-transform: uppercase;
    margin-top: 20px;
    font-weight: bold;
    opacity: 0.3;
    transition: all 0.3s ease-in-out;
    cursor: not-allowed;
    position: relative;
    &:hover {
      opacity: 0.5;
      &:before {
        transform: scale(0.3);
        border-bottom: 1px solid $primary;
      }
      &:after {
        transform: scale(0.3);
        border-top: 1px solid $primary;
      }
    }
    &::before {
      content: "";
      display: block;
      border-bottom: 1px solid $tint2;
      transform: scale(1);
    }
    &::after {
      content: "";
      display: block;
      border-top: 1px solid $tint2;
      transform: scale(1);
    }
  }
}
.pending__permission-details {
  height: 30px;
  svg {
    height: 12px;
    width: 12px;
    path {
      fill: $secondary-3;
    }
  }
  span {
    font-size: 12px;
    color: $secondary-4;
  }
}
.permission-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
}
.admin-access-tags {
  &__container {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &__add-container {
    display: flex;
    align-items: flex-start;
    margin-left: 8px;
    margin-top: 20px;
    input {
      min-width: 300px;
    }
    button {
      margin-top: 4px;
      margin-left: 20px;
    }
  }
  &__tag-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  &__tag {
    margin: 3px 8px;
    background: $primary-light;
    color: $primary;
    padding: 3px 10px 3px 10px;
    border-radius: 5px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    transition: all 0.5s ease-in;
    button {
      svg {
        path {
          fill: $primary;
        }
      }
    }
    &:hover {
      background: $primary;
      color: $white;
      button {
        svg {
          path {
            fill: $white;
          }
        }
        &:hover {
          background: rgba(0, 0, 0, 0.1) !important;
        }
      }
    }
    :not([disabled]):not([data-disabled]):focus {
      box-shadow: none !important;
      background: rgba(0, 0, 0, 0.05) !important;
    }
  }
}
