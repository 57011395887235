$brand-color: '#04B25F';
$bg-color: '#D1D5DB';
$teal: white;

// Range Slider
$range-width: 100% !default;

$range-handle-color: $brand-color !default;
$range-handle-color-hover: $teal !default;
$range-handle-size: 20px !default;

$range-track-color: $bg-color !default;
$range_track_height: 5px !default;

.alice-range-slider_wrapper {
  width: $range-width;
  height: $range_track_height;
  border-radius: 5px;
}

.alice-range-slider {
  -webkit-appearance: none;
  width: $range-width;
  height: $range_track_height;
  border-radius: 5px;
  background: transparent;
  outline: none;
  padding: 0;
  margin: 0;

  // Range Handle
  &::-webkit-slider-thumb {
    appearance: none;
    width: $range-handle-size;
    height: $range-handle-size;
    border-radius: 50%;
    background: white;
    border: 1px solid #d1d5db;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
      0px 1px 1px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: background 0.15s ease-in-out;

    &:hover {
      background: $range-handle-color-hover;
    }
  }

  &:active::-webkit-slider-thumb {
    background: $range-handle-color-hover;
  }

  &::-moz-range-thumb {
    width: $range-handle-size;
    height: $range-handle-size;
    border: 0;
    border-radius: 50%;
    border: 1px solid #d1d5db;
    background: white;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
      0px 1px 1px rgba(0, 0, 0, 0.12);
    transition: background 0.15s ease-in-out;

    &:hover {
      background: $range-handle-color-hover;
    }
  }

  &:active::-moz-range-thumb {
    background: white;
  }

  // Focus state
}
