.mt--15px {
  margin-top: -15px;
}
.mb-15px {
  margin-bottom: 15px;
}
.mr-15px {
  margin-right: 15px;
}
.mr-5px {
  margin-right: 5px;
}
.mr-10px {
  margin-right: 10px;
}
.ml-5px {
  margin-left: 5px;
}
.bg-white-old {
  background: #fff !important;
  &:hover,
  &:active,
  &:visited,
  &:focus {
    background: #fff !important;
  }
}
.btn-full {
  width: 100%;
  justify-content: center;
}
.text-transform-uppercase {
  text-transform: uppercase !important;
}

.btn-primary {
  background: $primary !important;
  color: $secondary-off-white !important;
  transition: all 200ms linear;
  &:hover,
  &:focus,
  &:active {
    background: $primary-dark !important;
    transition: all 200ms linear;
  }

  &:active {
    background: $secondary-2 !important;
  }
}
.btn-light {
  background: $secondary-off-white !important;
  color: $secondary-3 !important;
  transition: all 200ms linear;
  &:hover,
  &:focus,
  &:active {
    background: $secondary-3 !important;
    color: $white !important;
    transition: all 200ms linear;
  }

  &:active {
    background: $secondary-3 !important;
    color: $white !important;
  }
}

.border-color-top {
  border-top: 1px solid $border-color;
}

.color-secondary {
  color: $secondary-4;
}
.color-secondary-2 {
  color: $secondary-3;
}
.mtb-10px {
  margin: 10px 0px;
}
.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.d-flex {
  display: flex;
}
.f-center {
  align-items: center;
  justify-content: center;
}
.w-100px {
  width: 100px;
}
.w-90px {
  width: 80%;
}
.w-90p {
  width: 90%;
  input {
    width: 100%;
  }
}
.w-725px {
  width: 725px !important;
}
.w-100p-mr-10px {
  width: calc(100% - 10px) !important;
}
.w-100p {
  width: 100% !important;
}
.h-400px {
  height: 400px;
}
.w-50p {
  width: 50% !important;
}
.no-wrap {
  white-space: nowrap;
}
.mt-15px {
  margin-top: 15px;
}
.mt--6px {
  margin-top: -6px;
}
.mt-20px {
  margin-top: 20px;
}
.mt-5px {
  margin-top: 5px;
}
.mt-10px {
  margin-top: 10px;
}
.mr-20px {
  margin-right: 20px !important;
}
.mb-5px {
  margin-bottom: 5px !important;
}
.mt--10px {
  margin-top: -10px;
}
.mt--70px {
  margin-top: 70px !important;
}
.mt--40px {
  margin-top: 40px !important;
}
.mb-20px {
  margin-bottom: 20px;
}
.mt-30px {
  margin-top: 30px;
}
.pd-5px {
  padding: 5px !important;
}
.pd-10px {
  padding: 10px !important;
}
.pd-20px {
  padding: 20px !important;
}

.pl-8px {
  padding-left: 8px !important;
}
.pr-4px {
  padding-right: 4px !important;
}
.flex-justify-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}
.flex-end {
  justify-content: flex-end;
}
.p-label {
  font-size: 12px !important;
  color: $secondary-2 !important;
  margin-bottom: 5px !important;
  font-family: $poppins !important;
}
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.space-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.space-evenly {
  justify-content: space-evenly;
}
.align-center {
  align-items: center;
}
.align-flex-start {
  align-items: flex-start;
}
.center-flex {
  align-items: center;
  justify-content: center;
}
.text-center {
  text-align: center;
}
.hide {
  visibility: hidden !important;
}
.show {
  visibility: visible !important;
}
.overflow-visible {
  overflow: visible !important;
}
.flex-1 {
  flex: 1 1 !important;
}
.flex-2 {
  flex: 2 2 !important;
}
.flex-3 {
  flex: 3 3 !important;
}
.flex-4 {
  flex: 4 4 !important;
}
.flex-5 {
  flex: 5 5 !important;
}
.flex-6 {
  flex: 6 6 !important;
}
.flex-7 {
  flex: 7 7 !important;
}
.flex-8 {
  flex: 8 8 !important;
}
.flex-9 {
  flex: 9 9 !important;
}
.flex-10 {
  flex: 10 10 !important;
}
.flex-11 {
  flex: 11 11 !important;
}
.flex-12 {
  flex: 12 12 !important;
}
.fs-10 {
  font-size: 10px !important;
}
.align-start {
  align-self: flex-start !important;
}
.align-start-item {
  align-items: flex-start !important;
}
.pt-10px {
  padding-top: 10px;
}
.h-100p {
  height: 100%;
}
.h-100vh {
  height: 100vh;
}
.mh-200px {
  min-height: 200px;
}
.uppercase {
  text-transform: uppercase !important;
}
.absolute-top-right {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  align-items: center;
}
.overflow-hidden {
  overflow: hidden;
}
.text-danger {
  color: $danger !important;
  font-size: 0.75rem;
  font-weight: 500 !important;
}
.text-success {
  color: $success;
}
.bold {
  font-weight: bold;
}
.fw-500 {
  font-weight: 500 !important;
}
.flex-9p2 {
  flex: 9.2 !important;
}
.mb-30px {
  margin-bottom: 30px;
}
.mb-50px {
  margin-bottom: 30px;
}
.margin-auto {
  margin: 0 auto;
  text-align: center;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-10px {
  margin-bottom: 10px;
}
.mr-10-select {
  select {
    margin-right: 10px;
    + svg {
      margin-right: 10px;
    }
  }
}
.grey-text {
  font-size: 0.88rem;
  color: gray;
}

.fs-20px {
  font-size: 20px !important;
}
.flex-child-1 {
  div {
    flex: 1;
  }
}
.ml-20px {
  margin-left: 20px;
}
.fb-link {
  color: $facebook;
}
.ml-50px {
  margin-left: 50px;
}
.main-text {
  color: $text-color !important;
}
.full-loader {
  height: 450px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.block {
  display: block;
}
.p-relative {
  position: relative;
}
.pd-30px {
  padding: 30px;
}
.primary-text {
  color: $primary-2;
}
.ml-10px {
  margin-left: 10px;
}
.flex-5 {
  flex: 5;
}
.flex-15 {
  flex: 15;
}
.success-report {
  color: $success;
}
.danger-report {
  color: $danger;
}
.pdb-20px {
  padding-top: 20px;
  padding-bottom: 20px;
}

.h-200px {
  min-height: 200px;
}
.h-150px {
  min-height: 150px;
}
.h-300px {
  min-height: 300px;
}
.mh-80vh {
  min-height: 80vh;
}

.capitalize {
  text-transform: capitalize;
}
.link-color {
  color: $link-color !important;
}
.mr-0 {
  margin-right: 0;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}
.italic {
  font-style: italic;
}
.border-tint3-1px {
  border: 1px solid $tint3;
}
.primary-text {
  color: $secondary-rd !important;
}

.border-default-card {
  border-top: 0.5px solid $border-color;
  border-right: none;
  border-left: none;
  border-bottom: none;
}
.border-default-card-active {
  border: 0.5px solid $border-color !important;
}
.pdt-0px {
  padding-top: 0px !important;
}
