@import "../colors";
.button-group {
  background: white;
  border-radius: 5px;
  padding: 10px;
  margin: 0 -13px;
  &__droppable {
    display: flex;
    flex-wrap: wrap;
  }
  &__draggable {
    justify-content: center;
    flex: 1 1 150px;
  }
  &__child {
    background: $white;
    text-align: center;
    padding: 5px 10px;
    margin: 5px;
    border: 1px solid $secondary-off-white;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease-in;
    min-width: 13rem;
    &-title {
      background: $secondary-off-white;
      color: $secondary-3;
      border-radius: 5px;
      font-size: 0.9rem;
      padding: 5px;
    }
    &-subtitle {
      color: $secondary-3;
      font-size: 0.6rem;
      padding: 5px;
      word-break: break-all;
    }
    &__absolute {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: -10px;
      right: -5px;
      cursor: pointer;
      transform: scale(0.9);
      transition: all 0.3s ease-in-out;
      svg {
        fill: #ff6969 !important;
      }
      &:hover {
        transform: scale(1.2);
      }
    }
    &:hover {
      background: $secondary-off-white;
      .button-group__child__absolute {
        visibility: visible;
        opacity: 1;
      }
    }
    &.custom-api-button__field {
      cursor: default;
    }
  }
  .button-group-create-container {
    display: flex;
    .button-group__child {
      flex: 1;
    }
  }
}

.dropdown-menu-button {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  font-size: 0.95rem !important;
  color: $secondary-dark !important;
  font-weight: 600 !important;
  margin-bottom: 25px !important;

  img {
    width: 20px;
  }
}

.alice-btn-alt {
  color: #172b4d !important;
  font-size: 14px !important;
  line-height: 20px !important;
  background: rgba(9, 30, 66, 0.04) !important;
  border-radius: 3px !important;
  box-shadow: none !important;
}

.alice-btn-primary {
  color: #fff !important;
  font-size: 14px !important;
  line-height: 20px !important;
  background: #eebb4d !important;
  border-radius: 3px !important;
  box-shadow: none !important;

  &:disabled {
    background: rgba(9, 30, 66, 0.04) !important;
    color: #a5adba !important;
  }
}
