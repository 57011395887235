@import '../colors';

.subtitle {
  font-size: 12px;
  color: $secondary-4;
  margin-bottom: 15px;
}
.text-info {
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  font-weight: 500;
  color: $secondary-4;
  span {
    margin: -1px 0 0 5px;
  }
}
.flex-container {
  display: flex;
  justify-content: space-between;
  div {
    flex: 1 1;
    margin: 10px 10px 10px 0;
  }
}
