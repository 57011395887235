@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'reset';
@import 'style';

.without-ring {
  @apply focus:ring-0 focus:ring-offset-0;
}
@layer base {
  :root {
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}


// @layer base {
//   :root {
//     --background: 0 0% 100%;
//     --foreground: 222.2 84% 4.9%;

//     --card: 0 0% 100%;
//     --card-foreground: 222.2 84% 4.9%;

//     --popover: 0 0% 100%;
//     --popover-foreground: 222.2 84% 4.9%;

    // --primary: 222.2 47.4% 11.2%;
    // --primary-foreground: 210 40% 98%;

    // --secondary: 210 40% 96.1%;
    // --secondary-foreground: 222.2 47.4% 11.2%;

//     --muted: 210 40% 96.1%;
//     --muted-foreground: 215.4 16.3% 46.9%;

//     --accent: 210 40% 96.1%;
//     --accent-foreground: 222.2 47.4% 11.2%;

//     --destructive: 0 84.2% 60.2%;
//     --destructive-foreground: 210 40% 98%;

//     --border: 214.3 31.8% 91.4%;
//     --input: 214.3 31.8% 91.4%;
//     --ring: 222.2 84% 4.9%;

//     --radius: 0.5rem;
//   }

//   .dark {
//     --background: 222.2 84% 4.9%;
//     --foreground: 210 40% 98%;

//     --card: 222.2 84% 4.9%;
//     --card-foreground: 210 40% 98%;

//     --popover: 222.2 84% 4.9%;
//     --popover-foreground: 210 40% 98%;

//     --primary: 210 40% 98%;
//     --primary-foreground: 222.2 47.4% 11.2%;

//     --secondary: 217.2 32.6% 17.5%;
//     --secondary-foreground: 210 40% 98%;

//     --muted: 217.2 32.6% 17.5%;
//     --muted-foreground: 215 20.2% 65.1%;

//     --accent: 217.2 32.6% 17.5%;
//     --accent-foreground: 210 40% 98%;

//     --destructive: 0 62.8% 30.6%;
//     --destructive-foreground: 210 40% 98%;

//     --border: 217.2 32.6% 17.5%;
//     --input: 217.2 32.6% 17.5%;
//     --ring: 212.7 26.8% 83.9%;
//   }
// }

// @layer base {
//   * {
//     @apply border-border;
//   }
//   body {
//     @apply bg-background text-foreground;
//   }
// }
