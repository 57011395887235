@import '../../../../assets/styles/_colors.scss';
@import '../../../../assets/styles/typography';

.data-lab {
  &__hint {
    margin: 5px 0;
    font-size: 0.7rem;
    color: $secondary-4;
  }
  &__fieldcreate {
    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 234px);
    overflow: auto;
    padding: 0 60px 10px;
  }
  &__fieldcard {
    width: 100%;
    height: fit-content;
    background: $tint;
    padding: 18px 30px 18px 18px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1px solid lighten($tint2, 15);
    position: relative;
    &-length {
      margin-top: -20px;
    }
    &-elem {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
      &.action {
        justify-content: flex-end;
      }
      .flex-2 {
        flex: 2;
      }
      &-item {
        width: 100%;
        flex: 1;
        margin: 0 10px;
        align-items: center;
        justify-content: center;
        & div > input:disabled,
        button:disabled {
          background: $white;
          cursor: default;
          box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.14);
        }
        &-checkbox {
          input:checked + div,
          input:not([disabled]):checked:hover + div {
            background-image: linear-gradient(
              to bottom,
              $primary,
              $primary-dark
            );
          }
          span {
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 4px;
            line-height: 20px;
            color: #425a70;
            letter-spacing: -0.05px;
            font-family: 'SF UI Text', -apple-system, BlinkMacSystemFont,
              'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
              'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
          }
        }
        &-tagInput {
          width: 100%;
          background: white !important;
          input {
            box-shadow: none !important;
          }
        }
        &-label {
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 4px;
          line-height: 20px;
          color: #425a70;
          letter-spacing: -0.05px;
          font-family: 'SF UI Text', -apple-system, BlinkMacSystemFont,
            'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
            'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        }
        &-button {
          width: 100%;
          text-transform: capitalize;
          position: relative;
          svg {
            position: absolute;
            right: 20px;
          }
        }
      }
    }
    .saved-status {
      position: absolute;
      top: 10px;
      right: 45px;
    }
    .menu-icon {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
  &__body {
    height: calc(100vh - 4.1rem);
    padding: 25px 40px 0 20px;
    overflow: auto;
  }
  &__custom-next-button {
    width: 100%;
    margin-top: 25px !important;
    text-align: center;
    display: inline-block !important;
    .button-loader {
      display: inherit;
      margin-top: 10px;
    }
    svg {
      fill: $white !important;
      transform: translateY(3px) !important;
    }
  }
  &__header {
    background: $tint;
    border: 1px solid darken($tint, 4%);
    border-radius: 5px;
    width: 100%;
    height: 100px;
    transition: all 0.2s ease-in;
    position: relative;
    margin: 10px 0;
    &-card {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-weight: 500;
      font-family: $poppins;
      color: $secondary-2;
      font-size: 0.7rem;
      text-align: center;
      letter-spacing: 1px;
      margin-top: 40px;
      &-title {
        width: 36% !important;
        margin-left: 100px !important;
        text-align: left;
      }
      &-subtitle {
        width: 53% !important;
        text-align: left;
        margin-left: 40px;
      }
      &__stat-container {
        width: 90%;
        justify-content: space-between;
        &-clicks {
          width: 10% !important;
          text-align: left;
        }
        &-submitted {
          width: 10% !important;
        }
        &-keys {
          width: 10% !important;
          text-align: center;
          margin-right: 26px;
        }
      }
      &-date {
        width: 40% !important;
        text-align: left;
      }
    }
  }
  &__wrapper {
    background: $tint;
    border: 1px solid darken($tint, 4%);
    border-radius: 5px;
    width: 100% !important;
    height: 100px !important;
    transition: all 0.2s ease-in;
    position: relative;
    margin: 10px 0 !important;
    &-card {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      & img {
        width: 60px !important;
        height: 100% !important;
        margin: 10px !important;
      }
      &-title {
        width: 58% !important;
        margin-left: 20px !important;
        overflow: hidden !important;
        font-weight: 600;
        font-family: $poppins;
        color: $secondary-2;
        text-align: left;
      }
      &-subtitle {
        font-weight: 500;
        font-family: $poppins;
        color: $secondary-2;
        font-size: 0.8rem;
        width: 70% !important;
        text-align: left;
        margin-left: 20px;
        overflow: hidden;
        letter-spacing: 1px;
      }
      &-date {
        font-weight: 500;
        font-family: $poppins;
        color: $secondary-2;
        font-size: 0.8rem;
        width: 59% !important;
        text-align: left;
        letter-spacing: 1px;
      }
      &-no_data {
        font-weight: 500;
        font-family: $poppins;
        color: $secondary-2;
        font-size: 1rem;
        letter-spacing: 1px;
        margin-top: 200px;
        text-align: center;
      }
    }
    &:hover {
      cursor: pointer;
      background: $secondary-off-white-2;
    }
  }
  &__stat-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 65% !important;
    div {
      text-align: center;
      display: block;
    }
    .title {
      font-size: 0.5rem;
      color: $secondary-2;
      text-transform: uppercase;
    }
    .data {
      font-size: 1rem;
      font-weight: 500;
    }
  }
  &__dialog {
    width: 60%;
  }

  &__form {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 25% 20px;
    overflow: auto;
    height: calc(100vh - 4.1rem);
    h2 {
      margin-bottom: 30px;
    }

    &-container {
      width: 100%;
      background: $white;
      padding: 20px 40px;
      margin-top: 25px;
      border-radius: 10px;
      border: 1px solid $secondary-off-white;
      &-item {
        width: 100%;
        margin-top: 20px;
        input {
          width: 100%;
        }
        label {
          font-size: 0.9rem;
        }
      }
    }
    .flex-wrapper-3 {
      display: flex;
      justify-content: space-around;
      width: 100%;
    }
  }
  &__action {
    border-radius: 5px !important;
    display: flex !important;
    justify-content: space-between !important;
    &-button {
      font-size: 15px !important;
      font-weight: bold !important;
      width: 160px !important;
      height: 40px !important;
      justify-content: center !important;
      color: white !important;
      background: $primary-gradient !important;
      &:hover {
        cursor: pointer;
        background-color: #74e44e !important;
      }
    }
  }
}
.noData-Container {
  text-align: center;
  display: inline-block;
  margin: 0 auto;
}

[dir='rtl'] .css-1rprxtl[data-checked='true'] {
  transform: translateX(-50%) !important;
}
