.collapse-alice > details > summary:first-of-type {
  list-style-type: none;
}

.collapse-alice > details > summary::-webkit-details-marker {
  display: none;
}

.collapse-alice > details > summary > span > svg[data-icon='chevron-down'] {
  margin-top: -5px;
}
.collapse-alice > details > summary {
  padding: 16px 20px;
}
.collapse-alice > details[open] > summary {
  background-color: #f3f4f6;
  border-bottom: 1px solid #e5e7eb;
  border-radius: 8px 8px 0px 0px;
}

.collapse-alice
  > details[open]
  > summary
  > span
  > svg[data-icon='chevron-down'] {
  transition: 0.2s;
  rotate: 180deg !important;
  margin-top: -20px;
}

.collapse-alice > details[open] > summary > .subtitle {
  display: none;
}
