// we strongly discourage using custom css unless its really needed use tailwind class instead
// this file should only contains global custom styles if its really needed to the project
$secondary-color: #ffd82f;
.scroll-none::-webkit-scrollbar {
  display: none !important;
}

.scroll-none {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.content-holder {
  > div {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}

.nuka-carousel-paging-dot {
  background: white !important;
  border-radius: 50%;
  width: 12px !important; // change this according to your requirement
  height: 12px !important; // change this according to your requirement
  margin-left: 5px;
  margin-right: 5px;
  outline: none !important;

  svg {
    display: none !important;
  }

  li {
    margin-right: 5px;
  }
}

.nuka-carousel-paging-dot-container .active .nuka-carousel-paging-dot {
  background: $secondary-color !important;
}

.nuka-carousel-slide-button {
  @apply bg-white border-0 border-gray-300 shadow-md rounded-full p-3;
}

.h-400px {
  height: 400px;
}

.banner-added .hello-banner {
  display: block;
}

.banner-added .manipulateMenuModal {
  top: calc(18px + 40px);
}

// chat page
.banner-added .crm {
  height: calc(100vh - 45px) !important;
}

// inbox page
.banner-added .create-wooCoomerce-order {
  height: calc(86vh - 45px) !important;
}

// customer page
.banner-added .layout-body__no-scroll {
  height: calc(100vh - 60px);
}

// settings page
.banner-added .layout__wrapper > div {
  height: calc(100vh - 42px) !important;
}
.banner-added .canned-response-div {
  height: calc(100vh - 42px) !important;
}

// automation page
.banner-added .builder-main__dock {
  height: calc(100vh - 40px) !important;
  top: 40px;
}
.banner-added .builder-main__wrapper > div {
  height: calc(100vh - 42px) !important;
}

// broadcast page
.banner-added .custom-broadcast__modal {
  height: calc(100vh - 40px) !important;
  top: 40px;
}

.broadcast-table-body tbody {
  height: 520px;
}
.broadcast-table-body thead,
.broadcast-table-body tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.banner-added .broadcast-table-body tbody {
  height: 480px;
}
.broadcast-table-body::-webkit-scrollbar tbody :horizontal {
  width: 10px;
  height: 0.3em;
}
.broadcast-table-body::-webkit-scrollbar-track tbody :horizontal {
  border-radius: 10px;
}
.broadcast-table-body::-webkit-scrollbar-thumb tbody :horizontal {
  border-radius: 10px;
  background-color: #cbcfd9;
  outline: 1.5px solid rgb(179, 193, 207);
}

.react-tel-input .form-control:focus,
.react-tel-input .form-control:hover {
  border-color: rgba(4, 178, 95, 1);
  box-shadow: 0 0 0 1px rgba(4, 178, 95, 1);
}

#HW_badge_cont {
  height: 16px !important;
}

#HW_badge {
  top: 0 !important;
}

.nav-shadow {
  box-shadow: 0 -3px 4px 0 rgba(0, 0, 0, 0.1), 0 -1px 2px 0 rgba(0, 0, 0, 0.06);
}
#HW_frame_cont {
  left: 11.5% !important;
  top: 190px !important;
}

@layer extends components {
  label {
    @apply font-sans text-sm font-medium leading-6 text-gray-700;
  }
}

.tooltip {
  @apply invisible;
}

.has-tooltip:hover .tooltip {
  @apply absolute visible z-50;
}

/* react-datepicker customise classes*/
.react-datepicker {
  display: flex;
  ::selection {
    background: transparent !important;
  }
  &__header {
    background-color: white !important;
    border-bottom: none !important;
    &--time {
      padding: 1.125rem 0;
    }
  }
  &__day {
    &--selected {
      border-radius: 20px;
      background-color: #e5e7eb !important;
      color: #111827 !important;
    }
    &--keyboard-selected {
      border-radius: 20px;
      color: #111827 !important;
      background-color: transparent !important;
    }
    &:hover {
      border-radius: 20px;
      background-color: none !important;
    }
  }
  &__month {
    padding: 7px !important;
  }
  &-time__header {
    border: 1px solid #d1d5db;
    padding: 0.5rem 0;
    margin: 0 10px;
    border-radius: 5px;
    font-weight: normal;
  }
  &__time-list {
    height: 216px;
  }
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  display: flex;
  align-items: center;
  background: #04b25f;
  border-radius: 2px;
  &:hover {
    background-color: #04b25f !important;
  }
}
.react-datepicker__input-time-container{
  padding: 0 18px 10px;
}
.react-datepicker-time__input {
  width: 100%;
  border-radius: 10px;
  & focus {
    border-color: #04b25f !important;
  }
}

.custom-text-overflow::-webkit-scrollbar {
  width: 1em;
  height: 0.3em;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.custom-horizontal-text-overflow::-webkit-scrollbar {
  width: 10px;
  height: 0.3em;
}

.custom-horizontal-text-overflow::-webkit-scrollbar-track {
  border-radius: 10px;
}

.custom-horizontal-text-overflow::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #cbcfd9;
  outline: 1.5px solid rgb(179, 193, 207);
}

.custom-horizontal-text-overflow::-webkit-scrollbar:vertical {
  display: none;
}

/*react-datepicker customise classes*/

/*inbox 2.0 leftbar filter button css*/
.replied_unreplied_filter {
  @apply px-3 py-2 font-medium  hover:rounded-3xl;
}

.rotate-y-180{
  transform: rotateY('180deg');
}

.react-flow{
  height: calc(100% - 52px) !important;
  margin-top: 52px !important;
  &__panel.right{
    display: none;
  }
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */}
/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.AccordionTrigger[data-state='open'] .AccordionChevron {
  transform: rotate(180deg);
}

.error {
  color: #FF2323;
}

.subtitle-regular {
  color: #71717A;
  font-size: 0.875rem; /* text-sm */
  font-weight: 400; /* font-normal */
  line-height: 1.25rem; /* leading-5 */
}

.subtitle-medium {
  color: #71717A;
  font-size: 0.875rem; /* text-sm */
  font-weight: 500; /* font-medium */
  line-height: 1.25rem; /* leading-5 */
}

.label-primary {
  color: #18181B;
  font-size: 0.875rem; /* text-sm */
  font-weight: 500; /* font-medium */
  line-height: 1.25rem; /* leading-5 */
}

.detail-medium {
  color: #71717A;
  font-size: 0.75rem; /* text-xs */
  font-weight: 500; /* font-medium */
  line-height: 1.25rem; /* leading-5 */
}

.card-header-title {
  color: #111827;
  font-weight: 600; /* font-semibold */
  font-size: 0.875rem; /* text-sm (14px) */
  line-height: 1.25rem; /* leading-5 (20px) */
}

 .date-part {
   touch-action: none;
 }

 input[type="time"].hide-indicator::-webkit-calendar-picker-indicator {
   -webkit-appearance: none;
   display: none;
 }

// custom style for raven agent creation steper
.raven-step-container {
  .icon-btn {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .steps {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .single-step {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    height: 36px;
    //  border: 1px solid red;
  }

  .single-step:not(:last-child):after {
    content: "";
    background-color: white;
    border: 1px dashed #E4E4E7;
    width: 0px;
    height: 28px;
    position: absolute;
    top: 28px;
  }

  //  direction
  :dir(ltr) .single-step:not(:last-child):after {
    left: 9px;
  }

  :dir(rtl) .single-step:not(:last-child):after {
    right: 9px;
  }

  .step-done:not(:last-child)::after {
    border: 1px solid #22C55E;
  }
}
.custom-agent-thinking {
  .custom-agent-loader {
    clip-path: inset(0 1ch 0ch 0ch);
    animation: l4 0.7s steps(4) infinite;
  }
  @keyframes l4 {
    to {
      clip-path: inset(0 -1ch 0 0)
    }
  }
}


.loading-dots {
  width: 36px;
  aspect-ratio: 5;
  background: radial-gradient(circle closest-side, #fff 90%, transparent) 0/calc(100%/3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: loading-dots-animation 1s steps(4) infinite;
}

@keyframes loading-dots-animation {
  to {
    clip-path: inset(0 -34% 0 0);
  }
}
