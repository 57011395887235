@import "../../../../assets/styles/typography";
@import "../../../../assets/styles/colors";

.apifuse-main {
  width: 100%;
  &__header {
    margin-left: 50px;
    padding: 15px 0;
  }
}

.apifuse-main {
  margin: 25px auto 0;
  width: 100%;
  &__header {
    margin-left: 50px;
    padding: 15px 0;
    &__save-button {
      position: absolute !important;
      right: 30px !important;
      top: 20px !important;
      width: 200px !important;
      display: inline-block !important;
    }
  }
  &__wrapper {
    display: flex;
  }
  &__sequence {
    padding: 15px;
    background-color: $white;
    border: 1px solid $secondary-off-white;
    width: 300px;
    height: calc(100vh - 50px);
    overflow: auto;
    border-radius: 5px;
    margin: 0 10px 0 40px;
    > h5 {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-size: 0.9rem;
      img {
        height: auto;
        width: 16px;
        margin-right: 10px;
      }
    }
    &-meta {
      margin-bottom: 15px;
      h5 {
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: 0.8rem;
      }
      p {
        font-size: 0.75rem;
      }
    }
  }

  &__content {
    position: relative;
    padding: 0 30px 30px;
    flex: 1;
  }
}
.api-fuse-content {
  margin: 10px;
  &__container {
    background: $white;
    border-radius: 5px;
    border: 1px solid $secondary-off-white;
    padding: 10px 15px;
    margin: 0 0 20px;
    &__title {
      font-size: 1rem;
      color: $secondary-3;
      font-weight: 500;
    }
    &__subtitle {
      font-size: 0.7rem;
      color: $secondary-4;
      font-weight: 300;
      margin-bottom: 10px;
    }
  }
}
.key-value-pair {
  &__item {
    margin-top: 0;
    border-bottom: 1px solid $secondary-off-white;
    &:nth-child(1) {
      border-right: 1px solid $secondary-off-white;
      border-left: 1px solid $secondary-off-white;
    }
    &:nth-child(3) {
      border-right: 1px solid $secondary-off-white;
    }
  }
  &__inputs {
    background: $white !important;
    box-shadow: none !important;
    width: 100% !important;
    &:disabled {
      box-shadow: none !important;
      background: $white !important;
      cursor: text !important;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
  &__iconButton {
    margin-top: 15px !important;
    margin-left: 5px !important;
  }
}
.api-body__container {
  &__type {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  &__title {
    color: $secondary-3;
    font-size: 0.8rem;
  }
}
.api-resource {
  &__container {
    margin-top: 20px;
    font-family: $poppins;
    div {
      margin-bottom: 10px;
    }
    &-title {
      color: $secondary-3;
    }
    &-label {
      color: $secondary-3;
      font-weight: 500;
      margin-bottom: 10px;
    }
    &-url-input {
      width: 100% !important;
      margin-left: 15px;
    }
  }
}
.api-fuse__save-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    padding: 0 60px;
  }
}
