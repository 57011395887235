@import "../colors";

.button-popup {
  background: $white;
  padding: 15px;
  position: relative;
  &__absolute_-right {
    position: absolute;
    right: 5px;
    top: 5px;
  }
  h4 {
    font-size: 12px;
    margin-bottom: 10px;
  }
  h5 {
    font-size: 12px;
    margin-bottom: 5px;
    color: $secondary-3;
  }

  &__tab {
    &-item {
      border-radius: 0;
      cursor: pointer;
      &:focus {
        outline: 0;
      }
      &.tab-selected {
        border-bottom: 2px solid $primary;
      }
    }

    &-content {
      margin-top: 15px;
    }
  }

  &__hint {
    margin-top: 10px;
    font-size: 12px;
    color: $secondary-3;
    span {
      color: $secondary-4;
    }
  }
}
