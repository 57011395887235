@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/typography';
@import 'permission-project';

.menu-elem {
  [dir='ltr'] & {
    border-left: 3px solid transparent;
  }
  [dir='rtl'] & {
    border-right: 3px solid transparent;
  }
  transition: all 0.2s ease-in-out;
  &:hover {
    background: lighten($secondary-off-white, 3%);
    [dir='rtl'] & {
      border-right: 3px solid $tint2;
      border-radius: 60px 0px 0px 60px;
    }
    cursor: pointer;
    [dir='ltr'] & {
      border-radius: 0 60px 60px 0;
      border-left: 3px solid $tint2;
    }
  }
  &:focus {
    outline: none;
  }
  [dir='rtl'] & svg {
    margin-right: 16px;
    margin-left: 0;
  }
}

.menu-active {
  [dir='rtl'] & {
    border-right: 3px solid $primary;
    border-radius: 60px 0px 0px 60px;
  }
  [dir='ltr'] & {
    border-left: 3px solid $primary;
    border-radius: 0 60px 60px 0;
  }
  background: $secondary-off-white;

  color: $primary;
  span {
    font-weight: 500;
    font-family: $poppins;
  }
  &:focus {
    outline: none;
  }
}

.settings-card {
  background: white;
  border: 0.5px solid $border-color;
  padding: 25px;
  margin-bottom: 30px;
}
.settings-input {
  background: lighten($tint, 1%) !important;
  box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.05),
    inset 0 1px 1px rgba(67, 90, 111, 0) !important;
  height: 40px !important;
  margin-top: 5px !important;
  width: 75% !important;
  &:focus,
  &:hover {
    border: 1px solid $secondary-rd !important;
  }
}
.settings-input.bg-gray-200 {
  background-color: rgb(229 231 235) !important;
}
.settings-name__container {
  display: flex;
  width: 75%;
  div {
    flex: 1;
    [dir='rtl'] & {
      margin-left: 10px;
      margin-right: 0;
    }
  }
}
.w-75p {
  width: 75% !important;
}
.settings-container {
  display: flex;
  position: relative;
  > div {
    width: 100%;
  }
  &_central-elem {
    margin: 25px 45px 0 0;
  }
}
.settings-password__visible-button {
  position: absolute;
  top: 60%;
  right: 27%;
  color: $tint2;
  cursor: pointer;
}

.project-settings {
  .dropdown-menu-button {
    width: 100%;
    font-size: 1.5rem !important;

    img {
      width: 40px;
    }
  }
  .image-upload {
    width: 75% !important;
  }
}
.settings_facebook-page-card {
  background: $tint;
  padding: 10px 25px;
  margin: 10px;
  border-radius: 10px;
  .title {
    color: red;
  }
}
.no-underline {
  .title {
    color: $secondary-2;
    font-size: 1rem;
    font-family: $poppins;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .subtitle {
    color: $secondary-4;
    font-size: 0.7rem;
    font-family: $poppins;
    font-weight: 400;
    margin-top: -10px;
  }
}
.link-settings {
  color: $primary;
  font-weight: 500;
}
.wit-confidence-slider-container {
  margin: 10px 0 20px;

  .slider-container {
    width: 80% !important;
    cursor: pointer;
    &__marker {
      background: $primary;
      p {
        margin-top: 10px;
        font-size: 0.9rem;
        color: $secondary-2;
      }
    }
  }
}
[data-reach-slider-handle] {
  border: 2px solid $primary !important;
}
[data-reach-slider-marker][data-state='at-value'],
[data-reach-slider-marker][data-state='under-value'] {
  background: $primary-dark !important;
}

[data-reach-slider-track-highlight] {
  background: $primary !important;
}

@mixin card-mixin {
  width: 500px;
  font-size: 1em;
  border: 1px solid $tint2;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.card-section-container {
  display: flex;
  align-items: center;
}

.billing-card {
  width: 100%;

  .StripeElement {
    @include card-mixin;
    width: 100% !important;
    display: block;
    padding: 10px 14px;
  }

  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    transition: all 150ms ease;
  }
}

.payment-table {
  width: 53vw;
  overflow-x: auto;
  &__container {
    width: 1500px;
    overflow: auto;
  }
}

.tier-info {
  padding: 30px;
  min-width: 700px;

  .small__header {
    margin-bottom: 15px;
  }
}

.price-settings {
  &__info {
    display: flex;
    margin-top: 15px;
    font-size: 1rem;

    &-feature {
      border-radius: 19px;
      border: solid 1px #e5e9ee;
      padding: 20px;
      flex: 1;
      margin: 0 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h3 {
        color: $primary;
      }
      button,
      a {
        margin-top: 20px;
        justify-content: center;
      }

      ul {
        padding: 0;
        margin-top: 10px;
        li {
          list-style: none;
          position: relative;
          padding-left: 25px;
          font-size: 0.85rem;
          margin-bottom: 3px;
          &:before {
            position: absolute;
            top: 4px;
            left: 0;
            width: 16px;
            height: 16px;
            content: '';
            border: solid 1px $primary;
            border-radius: 50px;
            background-color: #e5e9ee;
          }
          &:after {
            position: absolute;
            top: 9px;
            left: 5px;
            width: 6px;
            height: 6px;
            content: '';
            border-radius: 50px;
            background-color: $primary;
          }
          button {
            margin: 0;
            padding: 0;
            margin-top: -4px;
            font-size: 0.85rem;
            font-weight: 600;
            color: inherit;
          }
        }
      }
    }

    &-estimation {
      button {
        color: $grey !important;
        text-decoration: underline;

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    &-price {
      span {
        font-size: 3.5rem;
        font-weight: 500;
      }
    }
  }
}

.card-container {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.credit-card {
  @include card-mixin;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $secondary-4;
  img {
    width: 40px;
    height: auto;
    margin-left: 15px;
  }
  p {
    margin-right: 15px;
  }
}

#password,
#nlp-settings {
  margin-bottom: 0;
}

.form-label {
  color: #425a70;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
}

.alice-connect-style.webchat {
  margin-top: 15px;
}

.webchat-field {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  span {
    color: $secondary-3;
  }

  .form-label {
    margin-right: 7px;
  }
}

.color-preview-container {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;

  .color-preview {
    width: 36px;
    height: 14px;
    border-radius: 2px;
  }
}

.settings__header {
  &-title {
    font-size: 1.75rem;
  }
}

.team-user-settings {
  padding: 30px;
  background-color: white;

  &__info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    h1 {
      font-size: 1.75rem;
      font-weight: 400;
    }
  }

  &__table {
    &-name {
      border: 1px solid rgba(#d3d7da, 0.8);
      display: flex;
      align-items: center;
      padding: 3px 5px;
      border-radius: 50px;

      > div {
        margin-right: 5px;
      }
    }

    &-role {
      span {
        display: flex;
        align-items: center;
      }
    }

    &-group {
      margin-left: 17px;
      div {
        border: 2px solid white;
        margin-left: -17px;
      }
    }
  }

  button:focus {
    box-shadow: none !important;
  }
}

.team-user-form {
  margin-bottom: 20px;
  &__item {
    p {
      margin-bottom: 5px;
    }
    input {
      width: 100%;
    }
  }

  &__item-submit {
    display: flex;
    justify-content: space-between;

    button:first-child {
      width: 280px;
    }
  }
}

.user-tag {
  margin-top: 20px;
  display: flex;
  &-item {
    margin-right: 15px;
    background: #cadcde;
    border-radius: 2px;
    padding: 7px 15px;
  }
}

.billing-settings {
  &-container {
    background: rgba(255, 255, 255, 0.81);
    border: 2px solid #dfe1e6;
    border-radius: 10px;
    padding: 40px;
  }
}

.plan-card {
  background: #ffffff;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
  padding: 30px;

  &-container {
    padding: 15px;
    padding-top: 0;
    max-width: 630px;
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &.active {
      background: #f4f5f7;
      border-radius: 3px;
      padding: 15px;

      p {
        margin-bottom: 10px;
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #172b4d;
    margin-bottom: 20px;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #bebebe;
    padding-bottom: 25px;
    padding-right: 80px;
    margin-bottom: 25px;

    &-item {
      h4 {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #6b778c;
      }
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #172b4d;
      }
    }
  }

  &__action {
    display: flex;
    justify-content: flex-end;

    * {
      margin-left: 10px !important;
    }
  }

  &__dialogue {
    padding: 20px;

    &-message {
      font-size: 20px;
      line-height: 24px;
    }
  }
}
