@import "../../../../assets/styles/colors";

body.form-body {
  background: $white;
}

.data-lab-form {
  &__container {
    margin: 20px auto;
    max-width: 600px;
    background: $tint;
    padding: 20px 20px 50px;
    border-radius: 5px;
    border: 1px solid lighten($tint2, 10%);
    .smart-input {
      margin-bottom: 20px;
      position: relative;
    }
    @media only screen and (max-width: 599px) {
      padding: 10px 20px 50px;
      margin: 0;
      border-radius: 0;
      border: none;
    }

    h2 {
      text-align: center;
    }
  }

  &__header {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    &-title {
      color: $secondary-2;
      font-size: 1rem;
      font-weight: 500;
      text-align: center;
    }
    img {
      width: 150px;
      height: auto;
      margin-bottom: 10px;
    }
  }

  &-submit {
    width: 100% !important;
    margin-top: 15px !important;
    font-size: 1.1rem !important;
    justify-content: center !important;
  }
}
