@import '../../../../assets/styles/colors';
@import './../../../../assets/styles/typography';
$crm-bg: #f3f4f6;
.crm-ticket-tag {
  &__search-bar {
    position: relative;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  &__search-icon {
    position: absolute;
    top: 12px;
    right: 12px;
    color: #7a859a;
  }
}

.crm {
  overflow: hidden;
  height: 100vh;

  &-container {
    margin: 0;
    display: flex;
    height: 100%;
    background: $crm-bg;
    overflow: hidden;

    .customer-list {
      width: 24%;
      border-right: 1px solid $border-color;
      height: 100%;

      &__tablist {
        background: $white;
        border: 1px solid $tint3;
        display: flex;
        margin: 0;
        height: 80px;
        color: gray;

        .active-tab {
          border-bottom: 4px solid $primary;

          &:hover {
            border-bottom: 4px solid $primary-dark !important;
          }
        }

        &-elem {
          flex: 1;
          display: flex;
          align-items: center;
          cursor: pointer;
          justify-content: center;
          border-bottom: 4px solid transparent;
          transition: all 0.3s ease;

          &:hover {
            border-bottom: 4px solid $primary-light2;
          }
        }
      }

      &__filter {
        min-height: 40px;
        background: white;
        border: 1px solid $tint3;
        margin: 10px 10px 10px 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        nav {
          width: 100%;
          display: flex;
          flex-wrap: wrap-reverse;
        }

        &-tab {
          background: $secondary-demi-white-2;
          color: $secondary-4;
          cursor: pointer;
          position: relative;
          flex: 1;
          min-width: 46%;
          margin: 5px 5px 3px;
          font-size: 0.65rem;

          .tab-badge {
            color: $white;
            background: $red;
            padding: 2px 3px;
            border-radius: 5px;
          }

          &:focus {
            outline-color: $primary;
          }
        }

        &-tab[aria-selected='true'] {
          background: $primary;
          color: $white;

          .tab-badge {
            color: $white;
            background: rgba(255, 255, 255, 0.15);
          }
        }
      }

      &__platform-filter {
        width: calc(100% - 2px);
        height: 75px;
        align-items: center;
        background: $white;
        padding: 15px;
        border-bottom: 1px solid $tint3;
        margin-left: 2px;
      }

      &__main-container {
        // previouly it was calc(100vh - 200px);
        // height: calc(100vh - 146px);
        height: calc(100% - 76px);
      }

      &__container {
        position: relative;
        overflow: auto;
        //margin: 2px 10px 10px 5px;
        height: calc(100% - 66px);
        border: 1px solid $tint3;
        padding: 5px 15px 15px;
        border-top: none !important;

        &-no-data {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          flex-direction: column;

          img {
            width: 16px;
            text-align: center;
            margin: 0 auto;
            opacity: 0.7;
          }

          p {
            text-transform: uppercase;
            font-size: 0.88rem;
            color: grey;
            margin-left: 10px;
          }
        }

        &-loader {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 30px;
        }

        &-loader__full {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 0 20px;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(255, 255, 255, 0.5);
          z-index: 1;
        }
      }
    }

    .customer-chat {
      width: 50%;
      border-right: 1px solid $border-color;

      &__reply-topHeader {
        height: 30px;
        display: flex;
        align-items: center;
        background: $default-color;
      }

      &__action-bar {
        background: white;
        height: 76px;
        border-bottom: 2px solid $tint3;
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        justify-content: space-between;

        &-admin {
          display: flex;
          align-items: center;

          &-buttons {
            display: flex;
            vertical-align: center;
          }

          .label {
            min-width: 80px;
            text-transform: uppercase;
            color: $secondary-4;
            font-size: 0.7rem;
            font-weight: 500;
            margin-bottom: 0;
          }
        }

        &-ticket {
          &-tag {
            margin-left: 10px;

            &-name {
              min-width: 40px;
              display: flex;
            }

            &-modal-more-tags {
              cursor: pointer;
              color: #184d47;
              margin-right: 10px;
              text-decoration: underline;
              font-size: 13px;
              font-weight: 500;
              background: none;
              border: none;
            }

            &-modal-button {
              color: #184d47;
              border: 0.5px solid #184d4770;
              border-radius: 2px;
              background: none;
              height: 25px;
              bottom: 4px;
              margin-top: 10px;

              &:hover {
                background: #184d47 !important;
                color: white !important;
                box-shadow: none !important;
                outline: none !important;
              }

              &:focus {
                box-shadow: none !important;
                outline: none !important;
              }
            }
          }
        }

        &-tag-button {
          margin: 0;
        }

        &-buttons {
          display: flex;

          &-bot-icon {
            margin-right: 5px;
          }
        }
      }

      &__container {
        overflow: auto;
        height: calc(100vh - 299px);
        margin: -1px 0 10px 0;
        padding: 10px 20px;
        background: white;
        border-bottom: 1px solid $border-color;
      }

      .reply-notes {
        background: $secondary-4;

        .reply-inputs {
          textarea {
            color: $white;

            &::placeholder {
              color: $white;
            }
          }
        }

        .reply-content {
          .reply-content__options {
            button {
              svg {
                fill: $tint !important;
              }
            }
          }
        }
      }

      &__replySection {
        background: white;
        margin: 10px 10px 10px 5px;
        border: 1px solid $tint3;
        border-radius: 3px;
        transition: all 0.3s ease-in;
        height: 118px;

        > .reply-inputs {
          display: flex;
          height: 40px;
          align-items: center;
          position: relative;

          textarea {
            background: transparent;
            flex: 10;
            resize: none;
            height: 100%;
            padding: 10px;
            min-height: 50px !important;
            font-size: 0.9rem;
            border: none;
            box-shadow: none;

            &:focus {
              outline: none;
              box-shadow: none;
            }

            &::-webkit-scrollbar {
              display: none;
            }
          }

          div {
            flex: 1;
            display: flex;
            justify-content: center;
            cursor: pointer;
            margin-right: 10px;
          }
        }

        > .reply-content {
          height: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          div {
            display: flex;
          }

          .reply-content__type {
            display: flex;
            align-items: center;

            button {
              height: 20px;
              font-size: 0.6rem;
              margin: 0 5px;
              padding: 4px 5px;
              border-radius: 3px;
              color: $secondary-3;
              background: lighten($tint3, 3%);
              cursor: pointer;
              border: none;
              transition: all 0.2s ease-in;
              text-transform: uppercase;
              margin: 0 0 0 10px;

              &:hover {
                background: $tint3;
                box-shadow: inset 0 0 0 1px $tint2, inset 0 1px 2px $tint2;
              }

              &:focus {
                outline: none;
              }
            }

            .active-message-type {
              background: $primary;
              color: $white;
              cursor: pointer;

              &:hover {
                background: $primary-dark !important;
                box-shadow: none;
              }
            }
          }

          .reply-content__options {
            display: flex;
            justify-content: center;
            align-items: center;

            button {
              svg {
                height: 1rem;
                width: 1rem;
              }
            }
          }
        }
      }
    }

    .customer-info {
      width: 26%;

      &__loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100% - 10px);
        background: white;
        border: 1px solid $tint3;
        margin-top: 3px;
      }

      &__right-topbar {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        background: $white;
        border: 1px solid $tint3;
        border-left: none;

        &-created {
          text-align: right;
          margin-right: 10px;

          p:first-child {
            font-size: 0.6rem;
            color: $secondary-4;
            text-transform: uppercase;
          }

          p:last-child {
            font-size: 0.88rem;
            text-transform: uppercase;
            color: $secondary-3;
            font-weight: 500;
          }
        }
      }

      &__action-bar {
        position: relative;
        background: white;
        height: 75px;
        border-radius: 3px;

        button {
          position: absolute;
          top: 6px;
          right: 3px;
        }
      }

      &__filter {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
    }
  }

  .customer-list {
    &__queue-title {
      margin: 10px 10px 8px 5px;
      text-transform: uppercase;
    }
  }
}

.ticket-card {
  padding: 20px 15px;
  box-shadow: 0 1px 1px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
  background: white;
  border-left: 4px solid transparent;
  cursor: pointer !important;

  &__active {
    border-left: 4px solid $secondary-rd;
  }

  &__content {
    display: flex;
    justify-content: space-between;

    &-left-info {
      display: flex;

      &-title {
        font-weight: 400;
        font-size: 0.875rem;
      }

      &-subtitle {
        font-weight: 400;
        font-size: 0.75rem;
      }

      &__user-info {
        justify-content: flex-start;
      }

      &__platform-info {
        align-items: center;
        display: flex;
        // background: $default-color;
        // border-radius: 3px;
        // padding-left: 5px;
        padding-right: 5px;
        width: fit-content;
      }
    }

    &-right-info {
      display: flex;
      flex-direction: column;

      &__time {
        font-size: 0.7rem;
        font-weight: 400;
        color: #6b778c;
        margin-top: 4px;
        margin-bottom: 3px;
      }

      &__flags {
        display: flex;
        justify-content: flex-end;
      }
    }

    &__avatar-container {
      position: relative;
      margin-right: 5px;

      &-platform {
        position: absolute !important;
        left: 20px;
        top: 20px;
        background: $default-color;
        border-radius: 25px;
        border: 1px solid $tint3;

        span {
          color: $primary;
        }
      }
    }
  }
}

.text-message-block {
  &__content {
    max-width: 65%;
    word-wrap: break-word;
    padding: 6px 12px 7px;
    font-size: 0.8rem;
    width: fit-content;
    margin-bottom: 2px;
  }
}

.chat-bubble-user {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:first-of-type {
    .text-message-block__content {
      border-top-left-radius: 1.3rem;
    }
  }

  &:last-of-type {
    .text-message-block__content {
      border-bottom-left-radius: 1.3rem;
    }
  }

  .text-message-block__content {
    background: $tint3;
    border-radius: 4px 1.3rem 1.3rem 4px;
  }

  .msg-time {
    font-size: 0.6rem;
    margin-bottom: 10px;
    margin-top: 3px;
    color: $tint2;
  }
}

.chat-bubble-admin {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &:first-of-type {
    .text-message-block__content {
      border-top-right-radius: 1.3rem;
    }
  }

  &:last-of-type {
    .text-message-block__content {
      border-bottom-right-radius: 1.3rem;
    }
  }

  .text-message-block__content {
    background: $primary-2;
    color: $white;
    border-radius: 1.3rem 4px 4px 1.3rem;
  }

  .msg-time {
    font-size: 0.6rem;
    margin-bottom: 10px;
    margin-top: 3px;
    color: $tint2;
  }
}

.user-info-crm {
  display: flex;
  margin-bottom: 5px;

  &__title {
    margin-right: 10px;
    font-family: $poppins;
    text-transform: capitalize;
    font-size: 0.7rem;
    color: $description-color;
  }

  &__value {
    font-family: $poppins;
    font-size: 0.7rem;
  }
}

.right-side-info-bar {
  background: transparent;
  // height: calc(100vh - 150px);
  height: calc(100% - 75px);
  padding: 10px 10px 10px 10px;
  border-radius: 3px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none !important;
  }
  &__expand {
    background: $white;
    margin-top: 3px;
    margin-bottom: 10px;
    border: 1px solid $tint3;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex: 0.3;
    margin-right: 3px;

    &:hover {
      background: darken($white, 1%);
      transition: all 0.3s ease-in;
      transform: translateX(2px);
    }
  }

  &__tag {
    box-shadow: none !important;
    padding-left: 0px !important;
  }

  &__user-info {
    border-bottom: 1px solid $tint3;
    padding-bottom: 20px;

    &-title {
      font-size: 0.88rem;
      font-weight: 500;
      color: $secondary-3;
      margin-top: 20px;
    }
  }

  &__user-container {
    &-title {
      font-family: $poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
    }

    &-details {
      font-family: $poppins;
      font-size: 0.625rem;
      color: $description-color;
    }
  }

  &__user-information {
    background: $white;
    box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
      0px 0px 1px rgba(9, 30, 66, 0.31);
    padding: 10px 20px 20px;
    h4 {
      margin-bottom: 10px;
    }
  }

  &__header {
    position: relative;
    display: flex;
    flex-direction: column;

    &-avatar-container {
      position: relative;
      display: block;
      margin: 0 auto;
      text-align: center;

      &-platform {
        position: absolute;
        right: 6px;
        bottom: 3px;
        background: white;
        padding: 2px;
        border-radius: 25px;
        width: 20px;
      }
    }

    &__user-info {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;

      p {
        font-size: 1rem;
        font-weight: 500;
        color: $secondary-2;
        text-align: center;
        margin: 15px 10px 0 10px;
      }

      &-location {
        font-size: 0.7rem !important;
        font-weight: 500 !important;
        color: $secondary-3 !important;
      }
    }

    &__user-platform {
      display: flex;
      flex-direction: row;
      padding-bottom: 10px;
      margin-left: 10px;
      align-items: center;
      justify-content: flex-start;

      p {
        font-size: 0.8rem;
        font-weight: 400;
        color: $secondary-3;
        text-align: center;
      }
    }
  }
}

.label-info {
  text-transform: uppercase;
  color: $secondary-4;
  font-size: 0.7rem;
  font-weight: 500;
  margin-bottom: 0;
  margin-left: 10px;
  margin-right: 20px;
}

.link-style {
  color: $primary;
  cursor: pointer;

  &:hover {
    color: $primary-dark;
  }
}

.update-info {
  &__container {
    &-edit-btn {
      display: none;
    }

    &:hover {
      .update-info__container-edit-btn {
        display: block;
      }
    }
  }
}

.chatData-fontfix {
  div {
    font-size: 0.88rem;
  }
}

.feed-container {
  background: $secondary-off-white !important;
}

.note-container {
  &__modal {
    &-textarea {
      resize: vertical;
      overflow: hidden;
    }
  }
}

.edit-user-info {
  &__modal-container {
    padding: 10px;
  }

  &__content {
    div {
      div {
        flex: 1;

        input {
          border-radius: 0px;
          box-shadow: inset 0 0 0 1px rgba(67, 111, 91, 0.3),
            inset 0 1px 2px rgba(67, 111, 81, 0.14);
        }
      }
    }

    &-dynamicadd {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      border: 1px dashed $primary;
      cursor: pointer;

      &-variable {
        display: flex;
        padding: 10px;
        background: $secondary-off-white;
        border-radius: 5px;
        margin-bottom: 15px;

        p {
          flex: 1;
        }
      }

      &-box {
        background: $white;
        border: 1px solid $primary-light-r;
        padding: 20px;
        margin-top: 20px;
        position: relative;

        &-caret {
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
        }

        div {
          flex: 1;

          &:first-child {
            margin-right: 10px;
          }
        }
      }

      &:hover {
        background: $secondary-off-white;
      }
    }
  }
}

.menu-style {
  padding: 20px 10px;
  cursor: pointer;
}

.canned-response {
  &-reply {
    &__body {
    }

    &__row {
      position: relative !important;
      overflow: visible !important;

      span {
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
      }
    }

    &__popup {
      width: 365px;
      padding: 10px;
      position: absolute;
      top: 5px;
      left: 205px;
      background: $white;
      border-radius: 2px;
      white-space: break-spaces;
      height: auto;
      box-shadow: 0 0 1px rgba(67, 90, 111, 0.3),
        0 8px 10px -4px rgba(67, 90, 111, 0.47);
    }
  }

  &__container {
    padding: 30px;

    button {
      &:focus {
        box-shadow: none !important;
      }
    }
  }

  &__header {
    margin-bottom: 30px;

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
  }

  &__remove-modal {
    padding: 20px;

    &-title {
      font-size: 1.25rem;
      font-weight: 500;
      color: $secondary-2;
    }

    &-subtitle {
      font-weight: 400;
      font-size: 0.875rem;
      color: $secondary-2;
    }
  }

  &__form {
    padding: 10px;

    &-item {
      input {
        width: 100% !important;
      }

      p {
        margin-bottom: 10px;
      }
    }
  }

  &__submit {
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 15px;
    }
  }

  &__table-title {
    span > span {
      font-weight: 600;
      color: $primary;
    }
  }

  &__table-text {
    span {
      white-space: break-spaces !important;
      text-overflow: unset !important;
      padding: 10px 5px !important;
      align-self: flex-start !important;
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  background-color: rgba($secondary-4, 0.1);
  opacity: 0.7;
  -webkit-border-radius: 100px;
}

::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

::-webkit-scrollbar-thumb:vertical {
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-height: 10px;
}

::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0, 0, 0, 0.61);
  -webkit-border-radius: 100px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100px;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0);
  min-width: 10px;
}

::-webkit-scrollbar-thumb:horizontal:active {
  background: rgba(0, 0, 0, 0.61);
  -webkit-border-radius: 100px;
}

.reply-icon-style-fix {
  cursor: pointer !important;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 7px 0 7px !important;

  &:hover {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
}

.reply-content__cancelButton {
  background: $secondary-rd !important;
  color: $white !important;
  margin-right: 0px !important;
  margin-bottom: 10px !important;
  display: inline-flex;
  align-items: center;
}

.reply-content__sendButton {
  background: $secondary-rd !important;
  color: $white !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
  display: flex;
  align-items: center;

  span {
    display: flex !important;
    align-items: center !important;
  }
}

.gallery-item-style-fix {
  img {
    height: 200px !important;
  }
}

.inconnect-chat-ui__gallery {
  ul {
    li {
      list-style: none;
    }
  }
}

.crm-filter {
  &__container {
    padding: 0 30px 60px 30px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    padding-top: 20px;
    padding-bottom: 10px;
    top: 0;
    background: white;
    z-index: 999;
  }
}

.assign-ticket {
  &__container {
    padding: 20px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &-content {
      display: flex;

      &__title {
        font-weight: 500;
        font-size: 0.875rem;
        color: $secondary-2;
      }

      &__subtitle {
        font-weight: 400;
        font-size: 0.75rem;
        color: $secondary-2;
      }
    }
  }

  &__info {
    margin-top: 20px;

    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-label {
        font-weight: 500;
        font-size: 0.875rem;
      }
    }
  }

  &__list {
    margin-top: 10px;

    &-title {
      color: #6b778c;
      font-weight: 600;
      font-size: 0.75rem;
      padding-bottom: 5px;
      border-bottom: 2px solid #dfe1e6;
    }

    &__container {
      height: 27vh;
      overflow-y: auto;
    }
  }

  &__alertModal {
    &__title {
      font-size: 1.25rem;
      font-weight: 500;
    }

    &__subtitle {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
}

.crm-image-gallery {
  padding: 10px 10px 20px;

  &__close-button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;
    max-width: 960px;
    justify-content: flex-start;
    @media (min-width: 420px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__item {
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    border: 2px solid white;
    @media (min-width: 420px) {
      width: 33.33%;
    }
    @media (min-width: 768px) {
      width: 25%;
    }

    div {
      padding: 0;
      margin: 0;
      width: 100%;
      background-color: white;
    }

    &-thumb {
      img {
        width: 100%;
        cursor: pointer;
        -webkit-filter: grayscale(30%);
        -moz-filter: grayscale(30%);
        filter: grayscale(30%);
        transform: scale(1.1);
        transition: all 0.5s ease;
        height: 120px;

        &:hover {
          cursor: pointer;
          -webkit-filter: grayscale(0%);
          -moz-filter: grayscale(0%);
          filter: grayscale(0%);
          transform: scale(1.2);
          transition: all 0.5s ease;
          border: 1px solid $secondary-rd;
        }
      }
    }
  }

  &__item-outlined {
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    border: 3px solid #eebb4d;
    @media (min-width: 420px) {
      width: 33.33%;
    }
    @media (min-width: 768px) {
      width: 25%;
    }

    div {
      padding: 0;
      margin: 0;
      width: 100%;
      background-color: white;
    }

    &-thumb {
      img {
        width: 100%;
        cursor: pointer;
        -webkit-filter: grayscale(30%);
        -moz-filter: grayscale(30%);
        filter: grayscale(30%);
        transform: scale(1.1);
        transition: all 0.5s ease;
        height: 120px;

        &:hover {
          cursor: pointer;
          -webkit-filter: grayscale(0%);
          -moz-filter: grayscale(0%);
          filter: grayscale(0%);
          transform: scale(1.2);
          transition: all 0.5s ease;
          border: 1px solid $secondary-rd;
        }
      }
    }
  }

  &__preview {
    margin-bottom: 30px;

    &-container {
      margin: 30px 20px 0;
      border: 1px solid $border-color;
      background-size: cover !important;
      min-height: 220px;
    }

    &-info {
      padding: 10px 15px;
      background: $default-color;
      margin: -1px 20px 0px;
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }
}

.gallery-upload {
  padding: 20px;
  background: $default-color;
  margin-bottom: 15px;
  border: 2px solid $border-color;
  border-radius: 5px;
  cursor: pointer;

  &__container {
    display: flex;
    justify-content: center;

    &:focus {
      outline: none;
    }
  }
}

.marketplace-connect-customer {
  &__container {
    padding: 10px;
  }

  &__data-container {
    box-shadow: 0 3px 5px rgba(9, 30, 66, 0.2), 0 0 1px rgba(9, 30, 66, 0.31);
    height: 250px;
    overflow-y: auto;
  }

  &__data-container-preview {
    margin-top: 20px;

    &__header {
      display: flex;
      align-items: center;
    }

    &__description {
      font-size: 0.75rem;
      font-weight: 400;
      margin-top: 15px;

      strong {
        font-weight: 500;
      }
    }
  }
}

.order-right-bar {
  &__container {
    box-sizing: border-box;
    box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
      0px 0px 1px rgba(9, 30, 66, 0.31);
    background: white;
    padding: 15px 10px;
  }

  &__order-lists {
    margin-top: 20px;
  }

  &__order-card {
    margin: 10px 0;
    border: 1px solid $border-color;
    box-sizing: border-box;
    border-radius: 6px;
    background: white;

    &__header {
      padding: 15px 10px 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &-title {
        font-size: 0.88rem;
        font-weight: 500;
      }

      &-subtitle {
        font-size: 0.8rem;
        color: $description-color;
        margin-top: 3px;
      }
    }

    &__content {
      padding: 5px 15px 5px 5px;

      &-title {
        font-size: 0.7rem;
        color: $description-color;
      }

      &-info {
        font-size: 0.7rem;
        color: $text-color;
      }
    }

    &__bill-amount {
      background: $default-color;
      border-radius: 3px;
      font-weight: 500;
      padding: 5px;
      margin-right: 5px;
      color: $text-color;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__summary {
      padding: 5px 15px 20px;

      &__info-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;

        &-title {
          font-size: 0.75rem;
          color: $description-color;
        }

        &-subtitle {
          font-size: 0.75rem;
          color: $text-color;
        }
      }
    }

    &__product {
      padding: 5px 15px 20px;
      &-element {
        display: flex;
        margin: 5px;
        justify-content: space-between;
        &-content {
          margin-bottom: 5px;
          margin-left: 10px;
          p {
            font-size: 0.75rem;
            margin-bottom: 5px;
            color: $description-color;
          }
          &-price {
            margin-bottom: 5px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.product-mask {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  overflow: hidden;

  img {
    max-width: 100%;
  }
}

.audio-player {
  &__crm {
    height: 80px;
    audio {
      height: inherit;
    }
  }
}
