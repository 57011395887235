.nodata__container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  text-align: center;
  img {
    width: 40%;
    margin: 10rem auto 0;
    opacity: 0.6;
  }
  p {
    font-size: 1rem;
    text-transform: capitalize;
    opacity: 0.6;
  }
}
