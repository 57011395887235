$poppins: "Inter", sans-serif;
$secondary-dark: #0b132b;

body {
  font-family: $poppins;
  font-size: 14px;
  line-height: 1.6;
  color: $secondary-dark;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $poppins !important;
  font-weight: 500;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

p {
  font-family: $poppins !important;
}
